
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      sh: {
        type: String,
        title: "The Greenhouse",
        img: "images/Greenhouse.png",
        imgAlt: "Salad on Table",
        bgColor: "var(--green)",
        bgImg: "images/salad.jpg",
        bgAlt: "bg Alt Tag",
        para: "",

        sub: "",
      },
    };
  },
});
